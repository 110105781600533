body {
  background-color: #f8f9fa;
}

.container {
  max-width: 1000px;
}

.navbar .container {
  max-width: 1000px;
}

/* Remove vertical lines between fields in data rows */
.custom-table td {
  border-right: none;
}

/* Agent logo styling */
.agent-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* Agent info styling */
.agent-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.agent-title {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.agent-description {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Star rating styling */
.star-rating svg {
  margin-right: 2px;
}

/* Table title styling */
.table-title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* Responsive adjustment */
@media (max-width: 768px) {
  .agent-table {
    margin-bottom: 2rem;
  }
}

/* Ensure each data row has a fixed height */
.data-row {
  height: 100px; /* Adjust the height as needed to fit two lines */
}

/* Style the description to handle two lines with ellipsis */
.fixed-description {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px; /* Adjust based on the row height */
}

/* Optional: Ensure consistent vertical alignment */
.agent-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Remove vertical borders between table cells */
.custom-table {
  border-collapse: collapse; /* Collapse borders for better control */
}

.custom-table th,
.custom-table td {
  border-left: none !important;  /* Remove left borders */
  border-right: none !important; /* Remove right borders */
  border-top: none;               /* Optionally remove top borders */
  border-bottom: 1px solid #dee2e6; /* Keep bottom borders for horizontal separators */
}

/* If you want to remove the top border of the first row */
.custom-table thead th {
  border-bottom: 2px solid #dee2e6; /* Enhance the header separation */
}
